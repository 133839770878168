import "leaflet/dist/leaflet.css"
import React from "react"
import { Col, Container, Row } from "reactstrap"
import ContactForm from "../components/contactForm"
import ContactMap from "../components/contactMap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import iconMail from "../images/icons/mail.png"
import iconMarker from "../images/icons/marker.png"
import iconMobile from "../images/icons/mobile.png"

const ContactPage = () => (
  <Layout className="subpage">
    <SEO
      title="Kontakt"
      description="Skontaktuj się z nami, jeśli potrzebujesz sprawdzonego studia projektowego. Zajmujemy się także produkcją mebli i nietypowymi realizacjami."
    />
      <section>
        <h2 className="section-header">Kontakt</h2>
        <Container>
          <Row>
            <Col md="6" className="contact-form-column">
              <ContactForm />
            </Col>
            <Col md="6" className="contact-address-column my-4 my-md-0">
              <Row>
                <Col md="4" xs="2">
                  <img
                    src={iconMarker}
                    className="icon-marker d-block ml-auto"
                    alt="Adres e-mail"
                    title="Adres e-mail"
                  />
                </Col>
                <Col>
                  <h6>Nobo Design</h6>
                  ul. Ząbkowska 14 / U3 <br />
                  03-735 Warszawa
                </Col>
              </Row>
              <Row>
                <Col md="4" xs="2">
                  <img
                    src={iconMail}
                    className="icon-mail d-block ml-auto"
                    alt="Adres e-mail"
                    title="Adres e-mail"
                  />
                </Col>
                <Col>
                <a 
                  href="#" 
                  className="cryptedmail" 
                  data-name="biuro" 
                  data-domain="nobodesign" 
                  data-tld="eu"
                  onClick={e => {
                    e.preventDefault();
                    window.location.href = 'mailto:' + e.target.dataset.name + '@' + e.target.dataset.domain + '.' + e.target.dataset.tld;
                  }}
                >&nbsp;</a>
                </Col>
              </Row>
              <Row>
                <Col md="4" xs="2">
                  <img
                    src={iconMobile}
                    className="icon-mail d-block ml-auto"
                    alt="Adres e-mail"
                    title="Adres e-mail"
                  />
                </Col>
                <Col><a href="tel:+48 888 999 604">+48 888 999 604</a></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <ContactMap className="contact-map" />
            </Col>
          </Row>
        </Container>
      </section>
  </Layout>
)

export default ContactPage
