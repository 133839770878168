import React, { Component } from "react"
import { FormGroup, Form, Label, Input, Button, Alert } from "reactstrap"

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }

  render() {
    const { status } = this.state
    return (
      <>
        {status === "SUCCESS" && (
          <Alert color="success">
            Dziękujemy! Twoja wiadomość została wysłana.
          </Alert>
        )}
        {status === "ERROR" && (
          <Alert color="danger">Wystąpił błąd. Spróbuj ponownie.</Alert>
        )}
        <Form
          onSubmit={this.submitForm}
          action="https://getform.io/f/ce5ade88-debb-41fd-85a0-b8e570042b1b"
          method="POST"
        >
          <FormGroup>
            <Input
              type="text"
              name="name"
              id="contactName"
              placeholder="Imię i nazwisko"
              required
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="email"
              name="email"
              id="contactEmail"
              placeholder="Adres e-mail"
              required
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="textarea"
              name="content"
              id="contactContent"
              placeholder="Treść wiadomości"
              required
            />
          </FormGroup>
          <FormGroup check className="my-4">
            <Label check>
              <Input type="checkbox" required />
              Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o
              ochronie danych osobowych w związku z wysłaniem zapytania przez
              formularz kontaktowy. Podanie danych jest dobrowolne, ale
              niezbędne do przetworzenia zapytania. Zostałem poinformowany, że
              przysługuje mi prawo dostępu do swoich danych, możliwości i
              poprawiania, żądania zaprzestania ich przetwarzania.
              Administratorem danych jest Nobo Design sp. z o.o., ul. Ząbkowska
              14 / U3, 03-735 Warszawa.
            </Label>
          </FormGroup>
          <FormGroup check row className="my-3">
            <Button className="w-100">Wyślij</Button>
          </FormGroup>
        </Form>
      </>
    )
  }
}
