import React, { Component } from "react"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import L from "leaflet"
import mapMarkerIcon from "../images/icons/mapmarker.png"

export default class ContactMap extends Component {
  render() {
    const position = [52.2531481, 21.0403242]

    const initMarker = ref => {
      if (ref) {
        ref.leafletElement.openPopup()
      }
    }

    if (typeof window !== "undefined") {
      const markerIcon = new L.Icon({
        iconUrl: mapMarkerIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [0, -50],
      })
      return (
        <Map center={position} zoom={17} {...this.props}>
          <TileLayer
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
            attribution='© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, © <a href="https://carto.com/attribution">CARTO</a>'
          />
          <Marker ref={initMarker} position={position} icon={markerIcon}>
            <Popup>
              <h6>
                <strong>Nobo Design</strong>
              </h6>
              ul. Ząbkowska 14 / U3 <br />
              03-735 Warszawa
            </Popup>
          </Marker>
        </Map>
      )
    }
    return null
  }
}
